import MostPopularCard from "./MostPopularCard";
import { destinations } from "@/destinations";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const MostPopularDestination = ({ locale }: any) => {
  return (
    <section
      id="most-popular-destination"
      className="py-[30px] lg:py-[60px] bg-white mt-[22px] lg:mt-[40px]"
    >
      <div className="max-w-7xl mx-auto px-[13px]">
        <h2 className="font-extrabold text-lg lg:text-3xl text-my-dark-blue mb-[22px] lg:mb-[40px]">
          Rental cars in the most popular destinations
        </h2>
        <div className="md:grid flex grid-cols-2 gap-x-[24px] gap-y-[28px] overflow-y-auto">
          {destinations.map((data) => {
            return (
              <MostPopularCard
                key={data.id}
                img={data.img}
                country={data.location}
                locations={data.locations}
                countryFull={data.countryName}
                locale={locale}
              />
            );
          })}
        </div>
        {/* <div className="lg:hidden grid">
          <Swiper
            slidesPerView={1.8}
            spaceBetween={10}
            className="mySwiper w-full h-full"
          >
            {popularData.map((data) => {
              return (
                <SwiperSlide key={data.id}>
                  <MostPopularCard
                    key={data.id}
                    img={data.img}
                    country={data.location}
                    locations={data.locations}
                    countryFull={data.countryName}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
      </div>
    </section>
  );
};

export default MostPopularDestination;
