export const destinations = [
  {
    id: 1,
    img: "/most-popular-dest/05.webp",
    location: "Texas",
    locations: [
      {
        name: "Houston",
        code: "IAH",
      },
      {
        name: "Austin",
        code: "AUS",
      },
      {
        name: "Dallas/Fort Worth",
        code: "DFW",
      },
      {
        name: "El Paso",
        code: "ELP",
      },
    ],
    countryName: "United States",
  },
  {
    id: 2,
    img: "/most-popular-dest/08.webp",
    location: "Florida",
    locations: [
      {
        name: "Tampa",
        code: "TPA",
      },
      {
        name: "Miami",
        code: "MIA",
      },
      {
        name: "Palm Beach",
        code: "PBI",
      },
      {
        name: "Pensacola",
        code: "PNS",
      },
      {
        name: "Orlando",
        code: "ORL",
      },
    ],
    countryName: "United States",
  },
  {
    id: 3,
    img: "/most-popular-dest/04.webp",
    location: "California",
    locations: [
      {
        name: "Fresno",
        code: "FAT",
      },
      {
        name: "Los Angeles",
        code: "LAX",
      },
      {
        name: "San Diego",
        code: "SAN",
      },
      {
        name: "San Francisco",
        code: "SFO",
      },
      {
        name: "San Jose",
        code: "SJC",
      },
    ],
    countryName: "United States",
  },
  {
    id: 4,
    img: "/most-popular-dest/01.webp",
    location: "New York",
    locations: [
      {
        name: "Washington DC",
        code: "WAS",
      },
      {
        name: "Newark",
        code: "EWR",
      },
      {
        name: "Albany",
        code: "ALB",
      },
      {
        name: "Las Vegas",
        code: "LAS",
      },
    ],
    countryName: "United States",
  },
];

export const nearYou = [
  {
    id: 1,
    img: "/destinations-near/01.webp",
    location: "Washington DC",
    price: 20,
    code: "WAS",
  },
  {
    id: 2,
    img: "/destinations-near/02.webp",
    location: "Pensa Cola",
    price: 20,
    code: "PNS",
  },
  {
    id: 3,
    img: "/destinations-near/03.webp",
    location: "Sane Jose",
    price: 20,
    code: "SJC",
  },
  {
    id: 4,
    img: "/destinations-near/04.webp",
    location: "Los Angeles",
    price: 20,
    code: "LAX",
  },
  {
    id: 5,
    img: "/destinations-near/05.webp",
    location: "Fresno",
    price: 20,
    code: "FAT",
  },
  {
    id: 6,
    img: "/destinations-near/06.webp",
    location: "Miami",
    price: 20,
    code: "MIA",
  },
  {
    id: 7,
    img: "/destinations-near/07.webp",
    location: "Orlando",
    price: 20,
    code: "ORL",
  },
  {
    id: 8,
    img: "/destinations-near/08.webp",
    location: "San Diego",
    price: 20,
    code: "SAN",
  },
];