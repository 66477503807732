import dateFormat from "dateformat";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CookieCutter from "cookie-cutter";
import getURL from "@/utils/getUrl";
import Image from "next/image";

type LocationType = {
    name: string;
    code: string;
};

const MostPopularCard = ({
    img,
    country,
    countryFull,
    locations,
    locale,
}: {
    img: string;
    country: string;
    countryFull: string;
    locations: LocationType[];
    locale?: string;
}) => {
    const router = useRouter();
    const [startDate, setStartDate] = useState(new Date());
    const initialEndDate = new Date(startDate);
    initialEndDate.setDate(startDate.getDate() + 1);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [region, setRegion] = useState("");
    const [userId, setUserId] = useState("");
    const [clickId, setClickId] = useState("anon");

    useEffect(() => {
        const userIdFromStorage = localStorage.getItem("userId");
        setUserId(userIdFromStorage || "");
    }, []);

    useEffect(() => {
        if (!router.isReady) return;

        const regionCookie = CookieCutter.get("region");
        regionCookie && setRegion(regionCookie?.toLowerCase());
    }, [router.isReady]);

    const generateKayakUrl = (location: string) => {
        const startDateWithTime = startDate;
        const endDateWithTime = endDate;
        startDateWithTime.setTime(startDate.getTime());
        endDateWithTime.setTime(endDate.getTime() + 60 * 60 * 1000);
        const kayakURL =
            `https://${getURL(
                locale || region
            )}/in?a=kan_187653_561320&encoder=27_1&enc_cid=${userId}_ka&enc_pid=r80_lp1_deeplinks&enc_eid=${clickId}&url=/cars/${
                location === "" ? "NYC" : location
            }/` +
            `${
                dateFormat(startDateWithTime, "yyyy-mm-dd") +
                "-" +
                dateFormat(startDateWithTime, "HH") +
                "h"
            }/${dateFormat(endDateWithTime, "yyyy-mm-dd")}`;
        return kayakURL;
    };
    return (
        <div className="flex flex-col lg:flex-row items-center justify-start border border-[#D8DFEE] min-w-fit rounded-[20px] max-w-[708px] overflow-clip lg:gap-5">
            <img
            loading="lazy"
                src={img}
                alt={`Picture of ${country}`}
                className="h-full w-full max-h-[144px] lg:max-w-[16rem] lg:min-h-[100%] object-cover"
            />
            <div className="w-full h-full p-3 lg:p-5 lg:px-0">
                <p className="font-bold text-base lg:text-[22px] text-my-dark-blue mb-4 lg:mb-[22px] leading-tight">
                    Top destinations in {country}
                </p>
                <div className="space-y-[18px] h-full ">
                    {locations.map((location, index) => (
                        <div
                            onClick={() =>
                                window.open(
                                    generateKayakUrl(location.code),
                                    "_blank"
                                )
                            }
                            className="flex items-center justify-between w-full cursor-pointer group"
                            key={index}
                        >
                            <p className="group-hover:underline font-bold text-[12px] lg:text-base text-my-light-blue truncate">
                                {location.name}{" "}
                                <span className="font-normal ">
                                    , {countryFull}
                                </span>
                            </p>
                            <div className="lg:h-[24px] lg:w-[24px] h-[18px] w-[18px] relative mr-2">
                                <Image
                                    fill
                                    src="/most-popular-dest/arrow-right.svg"
                                    alt="arrow-right-icon"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MostPopularCard;
